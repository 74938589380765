<template>
  <!-- wallets-spot/现货 -->
  <div class="deposit-history-wrap">
    <div class="deposit-history-box"  v-if="requestedStatus === 'requested'&&list.length">
      <div class="item-box" v-for="(item, index) in list" :key="index">
        <div class="item-header ub ub-btw font14">
          <div>
            USDT
          </div>
          <div>
            {{ $t('history.received') }}
          </div>
        </div>
        <div class="item-content ub ub-btw margin-top8">
          <div>
            <div class="content-label main-text-3 font10">
              {{ $t('history.amount') }}
            </div>
            <div class="font12">
              {{ item.trade_amount }}
            </div>
          </div>
          <div>
            <div class="content-label main-text-3 font10">
              {{ $t('history.fee') }}
            </div>
            <div class="font12">
              {{ item.fee }} USDT
            </div>
          </div>
          <div class="text-r">
            <div class="content-label main-text-3 font10">
              {{ $t('history.time') }}
            </div>
            <div class="font12">
              {{ item.start_time }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoData :refreshBtn="false" :tip="'No Data'" v-if="requestedStatus === 'requested'&& !list.length"> </NoData>
  </div>
</template>
<script>
import { fundBillList } from '@/api/assetsCenter.js'
import NoData from '@/components/market/noData'

export default {
  name: 'DepositHistory',
  components: { NoData },
  computed: {
  },
  data () {
    return {
      list: [],
      requestedStatus: 'pending'
    }
  },
  mounted () {
    this.fundBillListFn()
  },
  methods: {
    fundBillListFn () {
      fundBillList({
        trade_type: 1
      }).then(res => {
        this.list = res.data
        this.requestedStatus = 'requested'
        console.log(res, 'res----fundBillList')
        this.$toast.clear()
      })
    }
  }
}
</script>
<style lang="less">
.deposit-history-wrap{
  height: calc(100vh - 360px);
  .item-box{
    box-sizing: border-box;
    padding: 30px;
    border-bottom: 1px solid #ECEAEF;
  }
}
</style>
