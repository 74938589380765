<template>
  <!-- wallets-futures/合约 -->
  <div class="history-wrap">
    <van-nav-bar :border="false" left-text="" @click-left="back" left-arrow>
      <div slot="left">
        <img src="@/assets/images/system/left-arrow.svg"/>
      </div>
      <div slot="title">
        {{ $t('wallets.transactionHistory') }}
      </div>
    </van-nav-bar>
    <div class="history-tab-wrap">
      <van-tabs v-model="active">
        <van-tab v-for="(item,index) in tabList" :key="index" :title="$t(item.title)">
          <div class="components-wrap">
            <components :is="item.component"></components>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { fundBillList } from '@/api/assetsCenter.js'
import DepositHistory from './deposit/index.vue'
import WithdrawHistory from './withdraw/index.vue'
import BuyHistory from './buy/index.vue'

export default {
  name: 'HistoryWalltes',
  components: { },
  computed: {
    tabType () {
      return this.$route.params.type || 'deposit'
    }
  },
  props: {
  },
  data () {
    return {
      active: 0,
      tabKeyList: ['deposit', 'withdraw', 'buy'],
      tabList: [
        {
          title: 'wallets.buy',
          component: BuyHistory
        },
        {
          title: 'deposit.deposit',
          component: DepositHistory
        },
        {
          title: 'withdraw.withdraw',
          component: WithdrawHistory
        }
      ]
    }
  },
  mounted () {
    console.log(this.tabKeyList.indexOf(this.tabType), 'this.tabKeyList.indexOf(this.tabType)')
    this.active = this.tabKeyList.indexOf(this.tabType)
    this.fundBillListFn()
  },
  methods: {
    fundBillListFn () {
      fundBillList({}).then(res => {
        console.log(res, 'res----fundBillList')
        this.$toast.clear()
      })
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less">
.history-wrap{
  background-color: #fff;
  .history-tab-wrap{
    .components-wrap{
    }
    .van-tabs__wrap{
      padding: 0 30px;
      border-bottom: 1px solid #ECEAEF;
      .van-tab{
        flex:none;
        margin-right: 48px;
      }
      .van-tabs__line{
        background-color: var(--line-gradient-start-color);
      }
    }
  }
}
</style>
