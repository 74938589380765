<template>
  <div class="deposit-history-wrap" >
    <div class="deposit-history-box"  v-if="requestedStatus === 'requested'&&list.length">
      <div class="item-box" v-for="(item, index) in list" :key="index">
        <div class="item-header ub ub-btw font14">
          <div>
            <span>Buy</span>  USDT
          </div>
          <div class="font12 text-3">
            {{ formatDate(Date.now()) }}
          </div>
        </div>
        <div class="item-content ub ub-btw margin-top12">
          <div>
            <div class="content-label main-text-3 font10">
              {{ $t('withdraw.unitPrice') }}
              12.34 BRL
            </div>
            <div class="font12 text-3">
              {{ $t('trade.quantity') }}
              12.34 USDT
            </div>
          </div>
          <div class="text-r">
            <div class="content-label main-text-3 font16 text-1">
              {{ formatAmount(20000) }} BRL
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoData :refreshBtn="false" :tip="'No Data'" v-if="requestedStatus === 'requested'&& !list.length"> </NoData>
  </div>
</template>
<script>
import { fundBillList } from '@/api/assetsCenter.js'
import NoData from '@/components/market/noData'
import { mapGetters } from 'vuex'

export default {
  name: 'BuyHistory',
  components: { NoData },
  computed: {
    ...mapGetters(['locale'])
  },
  data () {
    return {
      list: [],
      requestedStatus: 'pending'
    }
  },
  mounted () {
    this.fundBillListFn()
  },
  methods: {
    fundBillListFn () {
      fundBillList({
        trade_type: 1
      }).then(res => {
        this.list = res.data
        this.requestedStatus = 'requested'
        console.log(res, 'res----fundBillList')
        this.$toast.clear()
      })
    },
    formatDate (dateString) {
      if (dateString) {
        const date = new Date(dateString)
        const options = { month: 'long', day: 'numeric' }
        const day = date.toLocaleDateString(this.locale.replace('_', '-'), options)
        const hours = date.getHours()
        const minutes = date.getMinutes()
        return `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes} ${day}`
      } else {
        return '--'
      }
    },
    formatAmount (num) {
      return num ? `${num.toLocaleString()}` : num
    }
  }
}
</script>
<style lang="less">
.deposit-history-wrap{
  height: calc(100vh - 360px);
  .text-3 {
    color: var(--main-text-3);
  }
  .text-1 {
    color: var(--main-text-1);
  }
  .item-box{
    box-sizing: border-box;
    padding: 30px;
    border-bottom: 1px solid #ECEAEF;
  }
}
</style>
